@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');


body{ font-family: "Outfit", sans-serif;}

.active {
    background-color: #fff;
   color: #1141BE;
}
.active path {
   stroke: #1141BE;
   fill: #1141BE;
}
.active span {
    color: #1141BE;
 }
 /************table css********************/
 table.dataTable thead th, table.dataTable thead td {
   padding: 16px 18px;
   border-bottom: 0;
}
table.dataTable.no-footer {
   border-bottom: 0;
}
.dataTables_wrapper .dataTables_length {
   float: left;
   margin-bottom: 15px;
}
table.dataTable{
   margin: 10px auto 10px auto;
}
table.dataTable tbody th, table.dataTable tbody td {
   padding: 15px 10px;
}
table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
   background-color: #fff;
}
table.dataTable.display tbody tr.odd>.sorting_1, table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
   background-color: #fff;
}
table.dataTable.display tbody tr.even>.sorting_1, table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
   background-color: #fff;
}
.dataTables_wrapper .dataTables_filter {
   padding: 18px 20px 0 14px;
}
.dataTables_wrapper .dataTables_length {
   padding: 18px 20px 0 14px;
}
.dataTables_wrapper .dataTables_info {
   padding-left: 13px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
   color: #fff !important;
   border: 1px solid #000e34;
   background: #000e34;
}
// li.border-l.-mb-px.bg-white {
//    color: #000;
//    background-color: #ededed;
// }
.dataTables_wrapper .dataTables_length select {
   min-width: 54px;
}
.dark_bg_two{background-color: #000e34; color: #fff;}
.white_text{color: #fff;}
// .success-snackbar{
//    position:absolute !important;
//    top: 0px !important;
//    //  background-color:green;
//    left: 0px !important;
//    right: 0px !important;
//    margin: auto !important;
//    //  width: 50% !important;
//    height: 50px !important;
//    bottom: inherit !important;
// }
// .mdc-snackbar__surface.ng-tns-c14-1 {
//    color: #fff;
//    text-align: center;
//    font-size: 16px;
//    // background-color: #000 !important;
//    background-color:#fff
//    // #1141BE !important;
// }
.mat-mdc-snack-bar-container {
   margin: 8px;
   position: absolute !important;
   top: 0 !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
   color: #fff;
   background-color: #1141BE !important;
}
.mdc-snackbar {
   top: 0px !important;
   bottom: inherit !important;
}
.heading_top {
   margin-top: -60px;
}

button[disabled] {
   opacity: 0.5; /* Adjust opacity to visually fade the button */
   pointer-events: none; /* Prevent clicks on the button */
 }
 .visually-hidden {
   display: none ;
}
.form-control {
   max-width: 100%;
}